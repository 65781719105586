
            <div class="">
                <!-- <div class="d-flex justify-content-center">
                    <img src="../../../../assets/logoSignPro.png" class="img-thumbnail border-0" alt="...">
                </div> -->

                <div id="numPadContainer" class="m-auto mw-200px">
                    <div class="pb-4 d-flex flex-wrap">
                        <div *ngFor="let nb of _randonIntegerArray; index as i"
                            class="col-4 pb-2 d-flex justify-content-center">
                            <button (click)="onTypingNumericalPad($event)" type="button" [ngClass]="_btnTheme"
                                class="btn btn-lg rounded-circle m-1">{{nb}}</button>
                        </div>

                        <div class="col-4 pb-2 d-flex justify-content-center">
                            <button (click)="onDeleteValue()" type="button"
                                class="btn btn-lg btn-warning rounded-circle m-1"><span class="h5"> < </span></button>
                        </div>
                        <div class="col-4 pb-2 d-flex justify-content-center">
                            <button (click)="onTypingNumericalPad($event)" type="button" [ngClass]="_btnTheme"
                                class="btn btn-lg rounded-circle m-1">{{_numericalPad[0]}}</button>
                        </div>
                        <div class="col-4 pb-2 d-flex justify-content-center">
                            <button (click)="onResetValue()" type="button"
                                class="btn btn-lg btn-danger rounded-circle m-1"><span class="h5">x</span></button>
                        </div>
                    </div>
                </div>

                <div class="input-group border border-secondary rounded">
                    <input #pin
                    [type]=" _hidePassword ? 'password' : 'text' " 
                    name="password" 
                    class="form-control bg-white border-0" 
                    data-toggle="password" 
                    spellcheck="false" 
                    autocorrect="off" 
                    autocapitalize="off" 
                    value={{_value}}
                    (keyup)="onTypingPinCode(pin.value)"
                    disabled />

                    <button (click)="onShowPassword()" 
                    class="btn btn-outline-secondary border-0" 
                    type="button" id="button-addon1"><img [src]="_hidePassword ? '../../../../assets/eye-xs.png' : '../../../../assets/eyeinvisible-xs.png'" class="img-thumbnail border-0 bg-transparent" alt="...">
                    </button>
                </div>
            </div>

            