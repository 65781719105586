// import { PageNotFoundComponent } from './cores/page-not-found/page-not-found.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './cores/auth-guard';
import { ExternalAuthGuard } from './cores/external-auth-guard';
import { MagicLinkComponent } from './pages/visitor/magic-link/magic-link.component';
import { ExternalAuthorizationComponent } from './pages/visitor/external-authorization/external-authorization.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'visitor' },
  {
    path: '',
    loadChildren: () => import('./pages/visitor/visitor.module').then((m) => m.VisitorModule)
  },
  {
    path: 'log',
    loadChildren: () => import('./pages/connected/connected.module').then((m) => m.ConnectedModule),
    canActivate : [AuthGuard]
  },
  {
    path: 'external',
    loadChildren: () => import('./pages/external/externalconnected.module').then((m) => m.ExternalConnectedModule),
    canActivate : [ExternalAuthGuard]
  },
  {
    path: 'auth/:guid',
    component : MagicLinkComponent
  },
  { 
    path: 'ext-auth',
    component : ExternalAuthorizationComponent
  }, 
  // { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}