import { Component, ElementRef, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';
const sha256 = require('js-sha256');

@Component({
  selector: 'app-magic-link',
  templateUrl: './magic-link.component.html',
  styleUrls: ['./magic-link.component.scss']
})


export class MagicLinkComponent implements OnInit {
  _magicLinkGuid: string = "";
  
  // Global state
  _isForbidden: boolean = false;
  _isUnauthorised: boolean = false;
  _isLoading: boolean = true;
  _hasError:boolean = false;
  _isTypingPinCode:boolean = false;
  _isPinCodeDisabled:boolean = false;
  _isSendingPinCode:boolean = false;
  _isPinToInitialize:boolean = false;
  
  _pinCode1: string = '';
  _pinCode2: string = '';
  
  // PinCode state
  _isPinCodesEqualsToInitialize : boolean = false;
  _isPinCodeError: boolean = false;
  _isPinCodeMismatch: boolean = false;
  _pinAttempt: number = 0;
  

  @ViewChild('pinCode') bindingPinCodeElement? : any;
  @ViewChild('initPinCode1') bindingPinCode1El?: any;
  @ViewChild('initPinCode2') bindingPinCode2El?: any;
  constructor(private route: ActivatedRoute, private auth: AuthService, private router: Router) { }


  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this._magicLinkGuid = params['guid'];
      if (this._magicLinkGuid) {
        this.auth.validateMagicLink(this._magicLinkGuid).subscribe(
          {
            next: (resp:any) => {
              this._isLoading = false;
              this._isTypingPinCode = true;
              this._isPinToInitialize = resp.body.PinToInitialize
            },
            error: (res: any) => {
              switch (res.status) {
                case 403: this._isForbidden = true; this._isLoading = false; break;
                case 401: this._isUnauthorised = true; this._isLoading = false; break;
                default: this._isLoading = false; this._hasError = this._hasError = true;break;
              }
            }
          })
      }
    })
  }

  onSendPinCodeButtonClicked(){
    this.sendPinCodeRequest()
  }

  onPinCodeValueChanged(pinCodeValue: any){
    this._pinCode1 = pinCodeValue;
    if(this._pinCode1.length == 6){
      this.sendPinCodeRequest();
    }
  } 


  onInitializingPinCode1(pinCodeValue: any){
    this._pinCode1 = pinCodeValue;
    if(this._pinCode1 != '' && this._pinCode1.length == 6){
      this._isPinCodesEqualsToInitialize = this._pinCode1 == this._pinCode2;
    } else this._isPinCodesEqualsToInitialize = false;
  }

  onInitializingPinCode2(pinCodeValue: any){
    this._pinCode2 = pinCodeValue;
    if(this._pinCode2 != '' && this._pinCode2.length == 6){
      this._isPinCodesEqualsToInitialize = this._pinCode1 == this._pinCode2;
    } else this._isPinCodesEqualsToInitialize = false;
  }

  private sendPinCodeRequest(){
    this._isPinCodeDisabled = true;
    this._isSendingPinCode = true;
    this._isPinCodeError = false;
    var hashPinCode1 = sha256(this._pinCode1);
    var hashPinCode2 = this._pinCode2 == "" ? "" : sha256(this._pinCode2);
    this.auth.validatePinCode(this._magicLinkGuid, [hashPinCode1,hashPinCode2] ).subscribe({
      next: (resp:any)=>{ 
        if(resp.ok){
            localStorage.setItem("Token", resp.body?.Token);
            var requestedUrl = localStorage.getItem("requestedUrl");
            if(requestedUrl){
              localStorage.removeItem("requestedUrl");
              window.location.replace(requestedUrl);
            } else{
              setTimeout(() =>{
                this.router.navigate(['/log'])
              }, 1000);
            }
            
        }
       },
      error: (res: any) =>{ 
        this._isPinCodeDisabled = false;
        this._isSendingPinCode = false;

        switch(res.status){
          case 401: this._isPinCodeError = true;this.resetPinCodeField(); break; // Cas ou il s'est trompé de code pin
          case 403: this._isUnauthorised = true; this._isTypingPinCode = false; break; // Cas ou le lien magique à expiré entre temps ou le nb de tentative dépasse les 3x.
          default: this._isLoading = false; this._hasError = true; this._isTypingPinCode = false; break; // Cas générique d'erreur.
        }
       }
    });
    this._pinAttempt += 1;
    if(this._pinAttempt >= 3){
      this._isTypingPinCode = false;
    }
  }

  private resetPinCodeField(){
    if(this._isPinToInitialize){
      this.bindingPinCode1El?.SetPinCode('');
      this.bindingPinCode2El?.SetPinCode('');
    }
    else this.bindingPinCodeElement?.SetPinCode('');
  }


}
