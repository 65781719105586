import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { concatMap, map, Subject, takeUntil, tap } from 'rxjs';
import { UserInfo } from '../shared/models/models_api/user-info';
import { AuthService } from '../shared/services/auth.service';
import { ParametresService } from '../shared/services/parametres.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  private ngUnsubscribe = new Subject();

    constructor(private _router: Router, private authService: AuthService, private paramService : ParametresService) { }

    // canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //     try{
  
    //         return this.authService.isUserConnected().pipe(takeUntil(this.ngUnsubscribe), map((resp: any ) =>{
    //             var tokenValue = localStorage.getItem('Token');
    //             if(!tokenValue){
    //                 this._router.navigate(['']);
    //                 localStorage.removeItem("Token");
    //                 return false;
    //             }
    //             else {
    //                 if(!resp.ok)
    //                 {
    //                     this._router.navigate(['']);
    //                     localStorage.removeItem("Token");
    //                 }
    //                 return resp.ok;
    //             }
    //         }))

    //             }catch(ex){
    //                 this._router.navigate(['']);
    //                 localStorage.removeItem("Token");
    //                 return false
    //             }
    // }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        try{
            var IsConnected:boolean = false;
            this.authService.hasConnectionChecked = false;
            return this.authService.isUserConnected().pipe(
                    tap((resp1:HttpResponse<any>) =>{
                        IsConnected = resp1.ok;
                        if(!IsConnected){
                            localStorage.setItem("requestedUrl",window.location.href);
                            window.location.href;
                            IsConnected = false;
                            this._router.navigate(['']);
                            localStorage.removeItem("Token");
                            this.authService.hasConnectionChecked = true;
                        }
                    }),
                    concatMap(resp2 => this.paramService.getUser_Info()),
                    tap({
                        next:(resp2:UserInfo) =>{
                            this.paramService.UserInfos = resp2; 
                            console.log("next")},   
                        error:(err)=>{
                            this.authService.hasConnectionChecked = true;
                            return IsConnected;
                        }}),
                    concatMap(resp3 => this.paramService.getUser_Authorizations()),
                    tap(() =>{}),
                    concatMap(resp4 => this.paramService.getCustomerAccount()),
                    tap(() =>{})
                ).pipe(takeUntil(this.ngUnsubscribe),map((resp:any)=>{
                    if (!IsConnected) {
                        this._router.navigate(['']);
                        localStorage.removeItem("Token");
                    }
                    this.authService.hasConnectionChecked = true;
                    return IsConnected;
                }))

                }catch(ex){
                    console.log("Erreur")
                    this._router.navigate(['']);
                    localStorage.removeItem("Token");
                    this.authService.hasConnectionChecked = true;
                    return false
                }
    }
}